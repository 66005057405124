import { Injectable } from '@angular/core';

const tokenKey = 'token';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _token: string | null = null;

  constructor() {
    const savedToken =
      sessionStorage.getItem(tokenKey) || localStorage.getItem(tokenKey);
    if (savedToken) {
      this._token = JSON.parse(savedToken);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.token;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get token(): string | null {
    return this._token;
  }

  setToken(token: string) {
    if (token) {
      const storage = localStorage;
      storage.setItem(tokenKey, JSON.stringify(token));
      this._token = token;
    } else {
      sessionStorage.removeItem(tokenKey);
      localStorage.removeItem(tokenKey);
    }
  }
}
