import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PassService } from 'src/app/core/pass.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit {
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  @ViewChild('successSwal') private successSwal: SwalComponent;


  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;

  controlsGroupForm: FormGroup;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  isCheckinMode = true;

  constructor(
    private formBuilder: FormBuilder,
    private passService: PassService
  ) {}

  ngOnInit(): void {
    this.controlsGroupForm = this.formBuilder.group({
      isCheckinMode: true,
    });
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.passService.checkPass(resultString, this.isCheckinMode).subscribe(res => {
      console.log(res);

    }, error => {
      console.log(`Scan error: `, error);
      // this.error = error;
      this.errorSwal.fire();
    });
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  test() {
    console.log('test');
    this.passService.checkPass('e5a2cb3acf0520d95c9e1ba2a66db2bb03f53384', this.controlsGroupForm.value.isCheckinMode).subscribe(res => {
      console.log(res);
      this.successSwal.fire();
    }, error => {
      console.log(`Scan error: `, error);
      // this.error = error;
      this.errorSwal.text = error.error.error;
      this.errorSwal.fire();
    });
  }
}
