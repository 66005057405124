import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ScannerRoutingModule } from './scanner-routing.module';
import { ScannerComponent } from './scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';


@NgModule({
  declarations: [ScannerComponent],
  imports: [
    CommonModule,
    ScannerRoutingModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    NgbModule,
    ZXingScannerModule
  ],
  exports: [ ]
})
export class ScannerModule { }
