import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from 'src/app/shell/shell.service';
import { ScannerComponent } from './scanner.component';

const routes: Routes = [

  Shell.childRoutes([
    { path: '', redirectTo: '/scanner', pathMatch: 'full' },
    { path: 'scanner', component: ScannerComponent, data: { title: 'Scanner' } }
  ])

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScannerRoutingModule { }
