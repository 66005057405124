import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PassService {
  private baseURL = `${environment.serverUrl}/pass`;

  constructor(private http: HttpClient) {}

  checkPass(code: string, isCheckin: boolean): Observable<any> {
    return this.http
      .post<any>(`${this.baseURL}/validate`, { code, isCheckin })
      .pipe(take(1));
  }
}
