import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,  Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { CredentialsService } from './credentials.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  isLoading = false;
  error: string | undefined;

  @ViewChild('errorSwal') private errorSwal: SwalComponent;


  constructor(
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder){
      this.createForm();
    }

  ngOnInit(): void {}

  login() {
    const login$ = this.authenticationService.login(this.loginForm.value);
    login$.pipe(
      finalize(() => {
        this.loginForm.markAsPristine();
        this.isLoading = false;
      }),
    ).subscribe(res => {
      this.credentialsService.setToken(res.token);
      console.log(`logged`, res);

      this.router.navigate([ this.route.snapshot.queryParams.redirect || '/scanner'], { replaceUrl: true });
    }, error => {
      console.log(`Login error: ${error}`);
      this.error = error;
      this.errorSwal.fire();
    });
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }
}
